<template>
  <div class="report-card-box-warper">
  <div class="report-card-box" @click="read(url)">
    <div class="report-date">
      <div>
        <img src="@/assets/image/btcinfo/report/pdf.png" alt="pdf">
        <div v-if="lang === 1">{{ year }}</div>
          <div v-if="lang === 2">{{ year2 }}</div>
          <div v-if="lang === 3">{{ year2 }}</div>
          </div>
        <div v-if="lang === 1">{{ date }}</div>
        <div v-if="lang === 2">{{ date2 }}</div>
        <div v-if="lang === 3">{{ date2 }}</div>
    </div>
    <div v-if="lang === 1" class="report-title">
      <div>
        {{ trueTitle }}
      </div>
    </div>
    <div v-if="lang === 2" class="report-title">
      <div>
        {{ title2 }}
      </div>
    </div>
    <div v-if="lang === 3" class="report-title">
      <div>
        {{ title3 }}
      </div>
    </div>
    <div class="report-download hand" @click.stop="download(url)">
      <img src="@/assets/image/btcinfo/report/download.png" alt="download">
      <div v-if="lang === 1">Download</div>
      <div v-if="lang === 2">Descargar</div>
      <div v-if="lang === 3">Descarregar</div>
    </div>
    <div class="report-read hand">
      <div v-if="lang === 1">Read</div>
      <div v-if="lang === 2">Leer</div>
      <div v-if="lang === 3">Ler</div>
      <div>></div>
    </div>
    <div v-if="data.BulletinID === 999 && lang === 1" class="free">Free</div>
    <div v-if="data.BulletinID === 9999 && lang === 2" class="free">Gratuito</div>
    <div v-if="data.BulletinID === 9998 && lang === 3" class="free">Grátiss</div>
  </div>
  </div>
</template>

<script>
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'BTCInfoReportCard',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    isEnExpire: {
      type: Boolean,
      default: false
    },
    lang: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      title: 'BTC Report',
      title2: 'Reporte de BTC',
      title3: 'Relatório de BTC'
    }
  },
  computed: {
    trueTitle () {
      const indexStart = this.data.BulletinTitle.indexOf('(')
      return this.data.BulletinTitle.slice(0, indexStart)
    },
    year () {
      const indexStart = this.data.BulletinTitle.indexOf('(')
      return this.data.BulletinTitle.slice(indexStart + 1, indexStart + 5)
    },
    year2 () {
      const split = this.data.BulletinTitle.split('(')
      return split[1].slice(0, 4)
    },
    date2 () {
      const split = this.data.BulletinTitle.split('(')
      if (split && split[1] && split[1].length > 12) {
        return split[1].slice(5, 16)
      } else {
        return split[1].slice(5, 10)
      }
    },
    date () {
      const indexStart = this.data.BulletinTitle.indexOf('(')
      const indexEnd = this.data.BulletinTitle.indexOf(')')
      return this.data.BulletinTitle.slice(indexStart + 6, indexEnd)
    },
    url () {
      return process.env.VUE_APP_STATIC + this.data.BulletinPath
    }
  },
  methods: {
    download (href) {
      if (this.$store.getters.token) {
        if (this.isEnExpire || this.data.BulletinID === 999) {
          fetch(href).then((response) => {
            return response.blob()
          }).then((arraybuffer) => {
            const blob = new Blob([arraybuffer])
            const link = document.createElement('a')
            const fileName = new Date().getTime() + '.pdf'
            link.href = window.URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            window.URL.revokeObjectURL(link.href)
          })
        } else {
          this.openMembershipModal()
        }
      } else {
        this.openLoginModal()
      }
    },
    read (url) {
      if (this.$store.getters.token) {
        if (this.isEnExpire || this.data.BulletinID === 999) {
          window.open(url)
        } else {
          this.openMembershipModal()
        }
      } else {
        this.openLoginModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.report-card-box-warper {
  display: inline-block;
}

.report-card-box {
  width: 353px;
  height: 106px;
  border-radius: 8px;
  background-image:url(../../../../assets/image/btcinfo/report/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 2px 2px 5px 2px #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px 0 10px;
  position: relative;
}

.report-date > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  font-size: 20px;
  font-weight: bold;
}

.report-date > div:first-child > img {
  width: 12px;
  height: 16px;
  margin-right: 10px;
}

.report-date > div:last-child {
  font-size: 14px;
  font-weight: bold;
}

.report-title {
  color: #014479;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  width:204px;
}

.report-download {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #014479;
  font-size: 10px;
  position: absolute;
  bottom: 0;
  right: 90px;
}

.report-download > img {
  width: 12px;
  height: 10px;
  margin-right: 5px;
}

.report-read {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #DF232A;
  font-size: 10px;
  position: absolute;
  bottom: 10px;
  right: 5px;
}

.report-read > div:first-child {
  margin-right: 5px;
}

.free {
  position: absolute;
  font-size: 12px;
  background: #e32229;
  border-radius: 3px 8px 3px 3px;
  color: #ffffff;
  padding: 0 5px;
  top: 0;
  right: 0;
}
</style>
