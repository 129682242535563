<template>
  <div class="line-box">
    <div class="title">
      <span>{{ title }}</span>
      <div/>
    </div>
    <div class="picker-country">
<!--      <div class="picker-country-img">
        <img v-if="languageIndex === 1" src="@/assets/image/btcinfo/report/EN_True.png" style="width: 48px; height: 22px;">
        <img v-if="languageIndex != 1" src="@/assets/image/btcinfo/report/EN_False.png" style="width: 26px; height: 17px;" @click="onLanguageChange(1)">
      </div>
      <div class="picker-country-img">
        <img v-if="languageIndex === 2" src="@/assets/image/btcinfo/report/Xiyu_True.png" style="width: 48px; height: 22px;">
        <img v-if="languageIndex != 2" src="@/assets/image/btcinfo/report/Xiyu_False.png" style="width: 26px; height: 17px;" @click="onLanguageChange(2)">
      </div>
      <div class="picker-country-img">
        <img v-if="languageIndex === 3" src="@/assets/image/btcinfo/report/Puyu_True.png" style="width: 48px; height: 22px;">
        <img v-if="languageIndex != 3" src="@/assets/image/btcinfo/report/Puyu_False.png" style="width: 26px; height: 17px;" @click="onLanguageChange(3)">
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'BTCInfoSectionTitle',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      languageIndex: 1,
      yearItem: '2022'
    }
  },
  methods: {
    // onLanguageChange (index) {
    //   this.languageIndex = index
    //   this.$emit('change-language', this.languageIndex)
    // }
  }
}
</script>

<style scoped>
.line-box {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0;
  width: 100%;
}

.title > span {
  margin-bottom: 5px;
  font-size: 30px;
  font-weight: bold;
}

.title > div {
  width: 40px;
  height: 2px;
  background: #87a5bd;
  margin: 0 auto;
}

.picker-country{
  width: 160px;
  height: 30px;
  /* background: rgba(230,230,230,0.39); */
  opacity: 1;
  border-radius: 6px;
  margin-left: 31px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  padding: 0 2px;
  margin-left: 300px;
  margin-right: 90px;
}

.picker-country-img{
  width: 52px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  line-height: 30px;
  cursor: pointer;
}

.content{
  display: flex;
  justify-content: flex-start;
}
</style>
